import React, { useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactPage() {
  const initialFormData = {
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6d50sjl",
        "template_04yghf6",
        e.target,
        "3kgr7beMymZLlPSYp"
      )
      .then((result) => {
        console.log(result.text);
        toast.success("Message sent successfully!");
        setFormData(initialFormData);
      })
      .catch((error) => {
        console.error(error.text);
        toast.error("Failed to send message. Please try again later.");
      });
  };

  const handlelocation = () => {
    window.open("https://maps.app.goo.gl/nZuNcLej3Nr5SSU59");
  };
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  const handlewhasApp = () => {
    window.open("https://wa.link/9uy80b");
  };
  return (
    <div>
      <Helmet>
     
        <title>
          Contact to Minaxi Palace Hotel in Rajasthan,Minaxi Palace Hotel
          Contact at Bhinmal
        </title>
        <meta
          name="description"
          content="If you have any query or concern related to hotel Booking and Reservations, please feel free to contact us on our customer care no +91 97832 99251."/>
        <meta
          name="keyword"
          content="Minaxi Palace Hotel Contact Us at Rajasthan|Minaxi Palace Hotel Contact Information at Jalore| Minaxi Palace Hotel Contact Details|Minaxi Palace Hotel Contact Form bhinmal|Minaxi Palace Hotel Contact Page|Minaxi Palace Hotel Reservations|Minaxi Palace Hotel Feedback|Minaxi Palace Hotel Location map|bhinmal|hotel in jalore rajasthan|bhinmal temple|bhinmal rajasthan jain temple"
        />
       
        <link rel="canonical" href="https://www.minaxipalace.in/contact" />
   
     
      

      </Helmet>

      <div className=" bg-dark text-white position-relative">
        <img
          src="./images/contact.jpg"
          className="card-img img-fluid d-flex"
          alt="/contact.jpg"
          style={{
            width: "100%",
            height: "420px",
            objectFit: "cover",
          }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <p className="card-title text-uppercase fs-3">Contact Us</p>
        </div>
      </div>
      <div className="container">
        <div className="row m-md-4 m-2">
          <div className="col-12">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.0060765805497!2d72.2695761751568!3d24.999909477838468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39436b5f96c622f3%3A0x47e7cfc1bec81d4b!2sHotel%20Minaxi%20Palace!5e0!3m2!1sen!2sin!4v1713457316263!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: "0px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>
          </div>
          <div
            className="col-12 d-none d-lg-block d-lg-flex justify-content-lg-around"
            style={{ marginTop: "-40px" }}
          >
            <div
              className="card bg-success text-white contact_card"
              style={{ width: "18rem" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  <a href="/contact">
                    <i
                      className="fa-solid fa-location-dot px-2"
                      onClick={handlelocation}
                      style={{ color: "white" }}
                    ></i>
                  </a>
                  Address
                </h5>
                <p className="card-text">
                  Ashapuri Market Near Rajput Circuit House, Opp. Jaswantpura
                  Railway Crossing, Bhinmal
                </p>
              </div>
            </div>
            <div
              className="card bg-success text-white contact_card"
              style={{ width: "18rem" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">Phone</h5>
                <p className="card-text">
                  <a href="/contact">
                    <i
                      className="fa-solid fa-phone px-1"
                      onClick={handlePhoneClick}
                      style={{ color: "white" }}
                    ></i>
                  </a>
                  +91 9982299252
                  <br />
                  <a href="/contact">
                    <i
                      class="fa-brands fa-whatsapp px-1"
                      onClick={handlewhasApp}
                      style={{ color: "white" }}
                    ></i>
                  </a>{" "}
                  +91 9783299251
                </p>
              </div>
            </div>
            <div
              className="card bg-success text-white contact_card"
              style={{ width: "18rem" }}
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  <a href="/contact">
                    <i
                      className="fa-solid fa-envelope px-2"
                      onClick={handleEmailClick}
                      style={{ color: "white" }}
                    ></i>
                  </a>
                  Email
                </h5>
                <p className="card-text">
                  For Any Query, You Can Mail us on minaxipalace@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-lg-none mt-3 mb-3 text-center">
            <div className="row justify-content-center">
              <div className="col-md-4 mb-3">
                <div className="card bg-success text-white">
                  <div className="card-body text-center contact_card">
                    <h5 className="card-title">
                      <a href="https://maps.app.goo.gl/nZuNcLej3Nr5SSU59">
                        <i
                          className="fa-solid fa-location-dot px-2"
                          onClick={handlelocation}
                          style={{ color: "white" }}
                        ></i>
                      </a>
                      Address
                    </h5>
                    <p className="card-text">
                      Ashapuri Market Near Rajput Circuit House, Opp.
                      Jaswantpura Railway Crossing, Bhinmal
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="card bg-success text-white">
                  <div className="card-body text-center contact_card">
                    <h5 className="card-title">Phone</h5>
                    <p className="card-text">
                      <a href="9982299252">
                        <i
                          className="fa-solid fa-phone px-1"
                          onClick={handlePhoneClick}
                          style={{ color: "white" }}
                        ></i>
                      </a>
                      +91 9982299252
                      <br />
                      <a href="9783299251">
                        <i
                          class="fa-brands fa-whatsapp px-1"
                          onClick={handlewhasApp}
                          style={{ color: "white" }}
                        ></i>
                      </a>{" "}
                      +91 9783299251
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="card bg-success text-white">
                  <div className="card-body text-center contact_card">
                    <h5 className="card-title">
                      <a href="minaxiplace@gmail.com">
                        <i
                          className="fa-solid fa-envelope px-2"
                          onClick={handleEmailClick}
                          style={{ color: "white" }}
                        ></i>
                      </a>
                      Email
                    </h5>
                    <p className="card-text">
                      For Any Query, You Can Mail us on minaxipalace@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="row p-md-5">
            <div className="col-12 text-center">
              <h6>CONTACT US</h6>
              <h2>Get In Touch With Us</h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6 col-12 mb-3">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name..."
                required
                className="form-control border-0 custom_input"
              />
            </div>
            <div className="col-md-6 col-12 mb-3">
              <input
                type="phone"
                name="phone"
                placeholder="Your Phone..."
                className="form-control  border-0 custom_input"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 col-12 mb-3">
              <input
                type="email"
                name="email"
                placeholder="Your Email..."
                className="form-control  border-0 custom_input"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="subject"
                placeholder="Subject..."
                className="form-control  border-0 custom_input"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12">
              <textarea
                className="contact_msg"
                placeholder="Your Message..."
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="pt-2">
              <button type="submit" className="btn btn-success mb-md-4 mb-2">
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
