import React from "react";

function Cancellation() {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  return (
    <div>
      <div className="container">
        <div className="row m-1 m-md-5">
          <div className="col-12">
            <h3 className="text-center  p-3">
              Cancellation and Refund Policy for MINAXIPALACE HOTEL & RESTAURANT
            </h3>
            <p className="pt-5" style={{ fontSize: "18px" }}>
              This Cancellation and Refund Policy governs the cancellation of
              bookings and refund processes for{" "}
              <span className="fw-bold"> MINAXIPALACE HOTEL & RESTAURANT.</span>{" "}
              By making a reservation through our website, you agree to abide by
              the following terms and conditions:
            </p>
            <h5 className="pt-4"> 1. Cancellation Policy</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Cancellation policies vary depending on the hotel and room type
              selected. Please review the terms and conditions of your
              reservation carefully before making any cancellations.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● To cancel your reservation, you must notify us in writing or
              through our online cancellation process. Cancellations made via
              phone may not be accepted.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● The cancellation deadline and applicable fees will be clearly
              stated in your booking confirmation email.
            </p>
            <h5 className="pt-4"> 2. Refund Policy</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Refunds for cancellations will be processed in accordance with
              the hotel's cancellation policy.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Refunds may be subject to cancellation fees, which vary
              depending on the timing of the cancellation and the hotel's
              policy.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Refunds will be issued to the original payment method used for
              the booking. Please allow up to{" "}
              <span className="fw-bold">[0-3 days]</span> for the refund to be
              processed and reflected in your account.
            </p>
            <h5 className="pt-4"> 3. No-Show Policy</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● If you fail to check in to the hotel on the scheduled arrival
              date without prior notice of cancellation, you may be charged a
              no-show fee equivalent to the total cost of the reservation.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● No-show fees are non-refundable and will be charged to the
              credit card provided at the time of booking.
            </p>
            <h5 className="pt-4"> 4. Modification Policy</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Modifications to reservations are subject to availability and
              the hotel's policy.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Changes to reservations may incur modification fees, which will
              be communicated to you before the changes are made.
            </p>
            <h5 className="pt-4 "> 5. Contact Us</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● If you have any questions or concerns about our cancellation and
              refund policy, please contact us at
              <span
                className="fw-bold"
                onClick={() => handleEmailClick("minaxipalace@gmail.com")}
              >
                <a
                  href="/"
                  style={{ textDecoration: "none", color: "black" }}
                  className="px-2"
                >
                  minaxipalace@gmail.com.
                </a>
              </span>
              Our customer service team will be happy to assist you.
            </p>
            <p className="pt-1 pt-md-3">
              <span className="fw-bold">MINAXI PALACE HOTEL</span>
              <br /> Ashapuri Market Near Rajput Circuit House,
              <br /> Opp. Jaswantpura Railway Crossing, Bhinmal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cancellation;
