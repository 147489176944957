import React, { useState } from "react";
import { Helmet } from "react-helmet";

const images = [
  {
    category: "rooms",
    src: "./images/gallaryroom1.jfif",
    alt: "/gallaryroom1.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom2.jfif",
    alt: "/gallaryroom2.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom3.jfif",
    alt: "/gallaryroom3.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom4.jfif",
    alt: "/gallaryroom4.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom5.jfif",
    alt: "/gallaryroom5.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom6.jfif",
    alt: "/gallaryroom6.jfif",
  },
  {
    category: "rooms",
    src: "./images/gallaryroom7.jfif",
    alt: "/gallaryroom7.jfif",
  },
  {
    category: "banquet",
    src: "./images/gallarybanquet1.jfif",
    alt: "/gallarybanquet1.jfif",
  },
  {
    category: "banquet",
    src: "./images/gallarybanquet2.jfif",
    alt: "/gallarybanquet2.jfif",
  },
  {
    category: "banquet",
    src: "./images/gallarybanquet3.jfif",
    alt: "/gallarybanquet3.jfif",
  },
  {
    category: "banquet",
    src: "./images/gallarybanquet4.jfif",
    alt: "/gallarybanquet4.jfif",
  },
  {
    category: "building",
    src: "./images/building1.jfif",
    alt: "/building1.jfif",
  },
  {
    category: "building",
    src: "./images/building2.jfif",
    alt: "/building2.jfif",
  },
  {
    category: "building",
    src: "./images/building3.jfif",
    alt: "/building3.jfif",
  },
  {
    category: "building",
    src: "./images/building4.jfif",
    alt: "/building4.jfif",
  },
  {
    category: "building",
    src: "./images/building5.jfif",
    alt: "/building5.jfif",
  },
  {
    category: "building",
    src: "./images/building6.jfif",
    alt: "/building6.jfif",
  },
  {
    category: "building",
    src: "./images/building7.jfif",
    alt: "/building7.jfif",
  },
];

export default function GalleryPage() {
  const [filter, setFilter] = useState("all");
  const [imageAnimation, setImageAnimation] = useState(false);

  const filterSelection = (category) => {
    setFilter(category);

    setImageAnimation(true);

    setTimeout(() => setImageAnimation(false), 700);
  };

  const filteredImages =
    filter === "all"
      ? images
      : images.filter((image) => image.category === filter);

  return (
    <div>
      <Helmet>
     
      
        <title>Photos Of Minaxi Palace Hotel In Bhinmal,Rajasthan</title>
        <meta
          name="description"
          content="Take a look inside Minaxi Palace Hotel, Views photos of Rooms, Restaurants, Banquets Hall etc."
        />
        <meta
          name="keyword"
          content="Best Minaxi palace hotel in Rajasthan|Top Hotels in Bhinmal|List of Hotels in Bhinmal|Hotels in Rajasthan|Images of Minaxi palace  hotel in Bhinmal|Photos of Minaxi palace hotel near Sanchore|Minaxi palace hotel in Rajasthan|Minaxi palace Restaurant in Bhinmal|Best Restaurant in Bhinmal|Banquet hall in Bhinmal|Restaurant for family in Bhinmal|bhinmal|bhinmal rajasthan jain temple|bhinmal temple|hotel in jalore rajasthan|bhinmal|bhinmal rajasthan jain temple"/>

       
        <link rel="canonical" href="https://www.minaxipalace.in/gallery" />
      

   
   
      

      </Helmet>
      <div className=" bg-dark text-white position-relative">
        <img
          src="./images/aboutbanner.jpg"
          className="card-img img-fluid d-flex"
          alt="/aboutbanner.jpg"
          style={{
            width: "100%",
            height: "420px",
            objectFit: "cover",
          }}
        />
        <div className="position-absolute  text-center start-50 translate-middle top-50">
          <p className="card-title text-uppercase fs-3">Gallery</p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center pt-5">
            <div id="myBtnContainer">
              <button
                className={`btn fw-bold ${filter === "all" ? "active" : ""}`}
                onClick={() => filterSelection("all")}
              >
                ALL
              </button>
              <button
                className={`btn fw-bold ${filter === "rooms" ? "active" : ""}`}
                onClick={() => filterSelection("rooms")}
              >
                ROOMS
              </button>
              <button
                className={`btn fw-bold ${
                  filter === "banquet" ? "active" : ""
                }`}
                onClick={() => filterSelection("banquet")}
              >
                BANQUET
              </button>
              <button
                className={`btn fw-bold ${
                  filter === "building" ? "active" : ""
                }`}
                onClick={() => filterSelection("building")}
              >
                BUILDING
              </button>
            </div>
            <div className="filtergallery row justify-content-around m-2">
              {filteredImages.map((image, index) => (
                <div
                  key={index}
                  className={`col-md-6 col-12 justify-content-center mb-3 p-0`}
                  style={{ width: "100%", maxWidth: "570px" }}
                >
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className={`img-fluid ${
                        imageAnimation
                          ? "animate__animated animate__zoomIn"
                          : ""
                      }`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
