import React from "react";

function TermsCondtion() {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  return (
    <div>
      <div className="container">
        <div className="row m-2 m-md-5">
          <div className="col-12">
            <h3 className="text-center  p-3">
              Terms and Conditions for MINAXIPALACE HOTEL & RESTAURANT
            </h3>
            <p className="pt-5" style={{ fontSize: "18px" }}>
              Welcome to{" "}
              <span className="fw-bold">MINAXIPALACE HOTEL & RESTAURANT.</span>{" "}
              These terms and conditions govern your access to and use of the
              Website. By accessing or using the Website, you agree to be bound
              by these Terms.
            </p>
            <h5 className="pt-4"> 1. Booking and Reservation</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● By booking a room through our Website, you agree to abide by the
              terms and conditions set forth by the hotel, including but not
              limited to cancellation policies, check-in/check-out times, and
              additional charges.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● All bookings are subject to availability and confirmation by the
              hotel.
            </p>
            <h5 className="pt-4"> 2. Payment</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Payment for your reservation is processed securely through our
              payment gateway.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● You agree to provide accurate and complete payment information.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Any additional charges incurred during your stay, such as room
              service, minibar, or damages, will be charged to the payment
              method provided at the time of booking.
            </p>
            <h5 className="pt-4"> 3. Cancellation and Modification </h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Cancellation and modification policies vary depending on the
              hotel and room type. Please review the terms and conditions of
              your reservation carefully before making changes or cancellations.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● Any cancellation or modification fees will be applied in
              accordance with the hotel's policy.
            </p>
            <h5 className="pt-4"> 4. User Conduct</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● You agree to use the Website only for lawful purposes and in a
              manner consistent with all applicable laws and regulations.
            </p>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● You agree not to use the Website in any way that could damage,
              disable, overburden, or impair the Website or interfere with any
              other party's use and enjoyment of the Website.
            </p>
            <h5 className="pt-4 "> 5. Indemnification</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● You agree to indemnify and hold harmless  <a
              className="fw-bold"
                  href="https://www.minaxipalace.in/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  (https://www.minaxipalace.in/)
                </a> and its affiliates, officers, directors, employees, and
              agents from any and all claims, liabilities, damages, costs, and
              expenses, including reasonable attorneys' fees, arising out of or
              in any way related to your use of the Website or your violation of
              these Terms.
            </p>

            <h5 className="pt-4"> 6. Governing Law</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ● These Terms shall be governed by and construed in accordance
              with the laws of [Your Jurisdiction], without regard to its
              conflict of law provisions.
            </p>
            <h5 className="pt-4"> 7. Changes to Terms</h5>
            <p className="pt-2 px-4" style={{ fontSize: "18px" }}>
              ●
              <span className="fw-bold"> MINAXIPALACE HOTEL & RESTAURANT </span>
              reserves the right to modify or replace these Terms at any time
              without prior notice. Your continued use of the Website after any
              such changes constitutes your acceptance of the new Terms.
            </p>
            <p className="pt-2 px-3" style={{ fontSize: "18px" }}>
              If you have any questions about these Terms and Conditions, please
              contact us at
              <span
                className="fw-bold"
                onClick={() => handleEmailClick("minaxipalace@gmail.com")}
              >
                <a
                  href="/"
                  style={{ textDecoration: "none", color: "black" }}
                  className="px-2"
                >
                  minaxipalace@gmail.com
                </a>
              </span>
            </p>
            <p className="pt-1 pt-md-3">
              <span className="fw-bold">MINAXI PALACE HOTEL</span>
              <br /> Ashapuri Market Near Rajput Circuit House,
              <br /> Opp. Jaswantpura Railway Crossing, Bhinmal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondtion;
