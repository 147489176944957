import React from "react";

const attractions = [
  {
    id: 1,
    imageSrc: "./images/sundhamata.jpeg",
    title: "Sundha Mata Temple",
    description:
      "The temple of Sundhamata was constructed by Dewal Pratiharas with the help of Imperial Chauhans of Jalore. The first inscription is from AD 1262, which describes victory of Chauhans and the downfall of Parmaras. The second inscription is from 1326, and the third one is from 1727.",
  },
  {
    id: 2,
    imageSrc: "./images/72jinalya.jpeg",
    title: "72 Jinalaya Laxmivallabh Parshvnath",
    description:
      "The 72 Jinalaya was built in memory of Gun Sagar Suriswarji Mahara in the year 1982. Spread over an area of about 80 acres, this temple was built in an octagonal shape.Built with white marble, Bounter Jainalaya is known for its immaculate beauty.",
  },
  {
    id: 3,
    imageSrc: "./images/khemkari.jpeg",
    title: "Kshemkari Mata Temple",
    description:
      "Kshemkari Mata Temple is an ancient temple dedicated to Mother Goddess Kshemkari, also known as Khimaj Mata. It is located on a mountain in Bhinmal, Jalore District of Rajasthan.",
  },
  {
    id: 4,
    imageSrc: "./images/jalorefort.jpeg",
    title: "Jalore Fort",
    description:
      "There is an old Shiva temple, a temple devoted to Lord Shiva. It was built by Kanhaddev Ruler of Jalore. Same was renovated by Mah Singh Ruler of Jodhpur and built a Samadhi Mandir of Shri Jalandharnath.",
  },
  {
    id: 5,
    imageSrc: "./images/nilkanthmahadev.jpeg",
    title: "Neelkanth Mahadev Temple",
    description:
      "The state of Rajasthan is famous for housing the deity of Lord Shiva in its Neelkanth Temple. The place was earlier known as Rajyapura and then as Paranagar. Neelkanth is one of the thousands of names of Lord Shiva.",
  },
  {
    id: 6,
    imageSrc: "./images/varahshyam.jpeg",
    title: "Shree Varah Shyam ji Temple",
    description:
      "Khatushyamji is believed to be the Deva of the Kaliyuga. It is said that the Hindu deity has obtained a boon from Lord Krishna that he would be revered by Krishna's own name (Shyam) and worshipped in that way.",
  },
  {
    id: 7,
    imageSrc: "./images/jawai.jpg",
    title: "Jawai ",
    description:
      "The idea of building a dam over River Jawai was conceived in 1903 as its flooding waters caused heavy damage in Pali and Jalore district during monsoon. It was finally given shape in 1946.",
  },
  {
    id: 8,
    imageSrc: "./images/jeerawala.jpg",
    title: "Jeerawala ",
    description:
      "Jirawala Tirth is a Jain temple in Jirawala village of Sirohi District in Rajasthan, India. It lies 58 km from Abu Road.",
  },
  {
    id: 9,
    imageSrc: "./images/abu.jpg",
    title: "Mount Abu ",
    description:
      "Other than its scenic beauty, Mount Abu is also popular as a seat of religious importance for Jains. The structural architectural wonders in Mount Abu.",
  },
];

export default function AttractionSection2() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <h4>Attraction near by Minaxi Palace Hotel Bhinmal</h4>
            <p className="mt-3">
              Discover the historical and religious splendor of Rajasthan, home
              to some of the most revered sites in India. The Sundhamata Temple,
              built by the Dewal Pratiharas with the help of the Imperial
              Chauhans of Jalore, stands as a testament to Rajasthan's rich
              history, with inscriptions dating back to AD 1262. The 72
              Jinalaya, constructed in 1982 in memory of Gun Sagar Suriswarji
              Mahara, showcases immaculate beauty with its octagonal shape and
              white marble. The Kshemkari Mata Temple in Bhinmal, dedicated to
              Mother Goddess Kshemkari, and the Old Shiva Temple in Jalore,
              renovated by Mah Singh of Jodhpur, further highlight the region's
              spiritual heritage.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex flex-wrap justify-content-center">
        {attractions.map((attraction) => (
          <div
            className="attraction_information m-3"
            style={{ width: "24rem" }}
            key={attraction.id}
          >
            <figure className="figure">
              <img
                src={attraction.imageSrc}
                className="figure-img img-fluid"
                style={{ width: "24rem", height: "16rem", objectFit: "fill" }}
                alt="/imageSrc"
              />
              <figcaption className="figure-caption fs-5 fw-bold text-dark px-3">
                {attraction.title}
              </figcaption>
            </figure>
            <div className="overlay">
              <div className="text fs-6 text-center p-5">
                {attraction.description}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <p className="mt-3">
              Rajasthan also houses the Neelkanth Temple, dedicated to Lord
              Shiva, initially known as Rajyapura and later Paranagar, and the
              Khatushyamji Temple, where Khatushyamji, believed to be the Deva
              of the Kaliyuga, is worshipped. The Jawai Dam, conceived in 1903
              and constructed in 1946 to prevent flooding in Pali and Jalore
              districts, is a significant landmark in the region. The Jirawala
              Tirth, a Jain temple located in Sirohi District, and the scenic
              yet spiritually significant Mount Abu, known for its architectural
              wonders and temples, offer a deep dive into the state's diverse
              religious traditions
            </p>
            <p>
              <p>
                Rajasthan boasts ancient temples and historical landmarks that
                bear witness to its rich cultural legacy. Whether exploring the
                victory inscriptions at Sundhamata Temple, the architectural
                beauty of the 72 Jinalaya Jain temple, or the spiritual essence
                of the Khatushyamji Kaliyuga Deva, visitors can immerse
                themselves in the state's vibrant history. Keywords such as
                "historical sites in Rajasthan," "religious tourism in
                Rajasthan," and "scenic beauty of Mount Abu" guide travelers to
                these remarkable sites, ensuring an unforgettable journey
                through Rajasthan's enchanting past and spiritual present.
              </p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
