import React from "react";
import AttractionSection2 from "./Attraction/AttractionSection2";
import { Helmet } from "react-helmet";

export const AttractionPage = () => {
  return (
    <div>
      <Helmet>
     
        <title>
          Best attraction place near bhinmal,Top attractions places in Rajasthan
        </title>
        <meta
          name="description"
          content="Minaxi Palace Hotel & Restaurant situated near Sundha mata temple,Kshemkari Mata temple and many tourism places in Bhinmal Rajasthan."
        />
        <meta
          name="keyword"
          content="tourist attractions famous in Bhinmal|tourist places in Bhinmal|Near Bhinmal tourist Place in Rajasthan|tourist places near by bhinmal|Top 10 tourist places in Rajasthan|bhinmal famous place to visit|Tourist place list for rajasthan|Attraction place in india|bhinmal|hotel in jalore rajasthan|bhinmal rajasthan jain temple|bhinmal temple"/>
        
        <link rel="canonical" href="https://www.minaxipalace.in/attraction" />
 
      </Helmet>
      <AttractionSection2 />
    </div>
  );
};
