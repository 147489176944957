import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Componets/Navbar";
import { HomePage } from "./Screenpage/HomePage";
import Footer from "./Componets/Footer";
import AboutPage from "./Screenpage/AboutPage";
import RoomsPage from "./Screenpage/RoomsPage";

import { AttractionPage } from "./Screenpage/AttractionPage";
import BanquetPage from "./Screenpage/BanquetPage";
import ContactPage from "./Screenpage/ContactPage";
import { Restarant } from "./Screenpage/Restarant/Restarant";
import GalleryPage from "./Screenpage/GalleryPage";
import PrivacyPolicy from "./Screenpage/PrivacyPolicy";
import TermsCondtion from "./Screenpage/TermsCondtion";
import Cancellation from "./Screenpage/Cancellation";
import PageNotFound from "./Screenpage/PageNotFound";




function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><Navbar/><HomePage /><Footer/></>} />
          <Route path="/about" element={<><Navbar/><AboutPage /><Footer/></>} />
          <Route path="/rooms" element={<><Navbar/><RoomsPage /><Footer/></>} />
          <Route path="/gallery" element={<><Navbar/><GalleryPage /><Footer/></>} />
          <Route path="/banquet" element={<><Navbar/><BanquetPage /><Footer/></>} />
          <Route path="/attraction" element={<><Navbar/><AttractionPage /><Footer/></>} />
          <Route path="/contact" element={<><Navbar/><ContactPage /><Footer/></>} />
          <Route path="/restaurant" element={<><Navbar/><Restarant /><Footer/></>} />
          <Route path="/privacy-policy" element={<><Navbar/><PrivacyPolicy /><Footer/></>} />
          <Route path="/terms-condition" element={<><Navbar/><TermsCondtion /><Footer/></>} />
          <Route path="/cancellation" element={<><Navbar/><Cancellation /><Footer/></>} />
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
