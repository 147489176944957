import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
export default function BanquetPage() {
  const [activeIndex, setActiveIndex] = useState(0);

  const carouselImages = [
    "./images/banquet1.jfif",
    "./images/banquet2.jfif",
    "./images/banquet3.jfif",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [carouselImages.length]);

  return (
    <div>
      <Helmet>
      
        <title>
          Best Banquet halls in Rajasthan ,Top Banquet Halls In Bhinmal
        </title>
        <meta
          name="description"
          content="Minaxi Palace offers elegant and flexible meeting and event spaces with superior services and luxury Rooms and Banqute Hall."/>
        <meta
          name="keyword"
          content="Banquet halls in Rajasthan|Banquet halls near bhinmal|Affordable banquet halls in Rajasthan|Minaxi Palace Banquet Hall Near me|Best banquet Halls in Bhinmal|Minaxi Hotel banquet Halls near by|list of banquet halls near by jalore|Banquet halls in  Bhinmal|bhinmal|hotel in jalore rajasthan|bhinmal rajasthan jain temple|bhinmal temple"
        />
       
        <link rel="canonical" href="https://www.minaxipalace.in/banquet"/>
   
     
     
    

      </Helmet>
     
      <div className="container">
        <div className="row m-lg-5">
          <div className="col-md-6 col-12">
            <h1>
              <span style={{ color: "#EE5A24" }}>B</span>anquet
            </h1>
            <p>
            Elevate your event experience with our luxury Banquet Hall, located on the third floor of Minaxi Palace Hotel in Bhinmal. Covering approximately 3000 sq ft, this luxury event space is perfect for hosting meetings, get-togethers, and celebrations for 100-200 guests. With its impressive ceiling height and a pillar-less design, our multi-purpose banquet hall offers an ambiance of sophistication and versatility, making it an ideal choice for both formal and informal gatherings.
            </p>
            <div>
              <p>
                <i
                  className="fa-solid fa-circle-right fs-4 px-1"
                  style={{ color: "burlywood" }}
                />
                <span className="fw-bold ">Location:</span>Third Floor
              </p>
              <p>
                <i
                  className="fa-solid fa-circle-right fs-4 px-1"
                  style={{ color: "burlywood" }}
                />
                <span className="fw-bold ">Area:</span> 3000 Sq ft
              </p>
              <p>
                <i
                  className="fa-solid fa-circle-right fs-4 px-1"
                  style={{ color: "burlywood" }}
                />
                Hygienic Crockery
              </p>
              <p>
                <i
                  className="fa-solid fa-circle-right fs-4 px-1"
                  style={{ color: "burlywood" }}
                />
                Comfortable Seating
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {carouselImages.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    <img
                      src={imageUrl}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
          
           
            <p>
              <p>
               
              Our team of experts specializes in event planning in Bhinmal, ensuring every aspect of your event is meticulously handled. We provide hygienic crockery and comfortable seating to enhance your guests' experience. Additionally, our staff can assist in selecting the perfect local entertainment and decor to match your event’s theme and needs. From corporate events to personal celebrations, our banquet hall provides the perfect setting to host memorable occasions.
              </p>
              <p>Located in the heart of Bhinmal, our event venue combines convenience with elegance, offering a grand backdrop for any event. Whether you're planning a business meeting, wedding reception, or social gathering, our attentive staff will guide you through every step of the planning process, ensuring a flawless execution. Choose Minaxi Palace Hotel for your next event and experience top-notch service and a luxurious environment.</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
