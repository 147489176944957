import React from 'react';
import FastMarquee from 'react-fast-marquee';

export default function HomeSection5() {
  
  return (
    <div>
      <div className='container-fluid'>
        <div className='row p-5'>
          <div className='col-12'>
         
            <p className='text-center small-distance' style={{fontSize:"26px"}}>Distance @</p>
            
            <p style={{fontSize:"40px",fontWeight:"500"}}  className='text-center distance-text'> <span style={{ color: "#EE5A24" }}>M</span>inaxi Palace Hotel , Bhinmal</p>
          </div>
        </div>
        <div className='row mb-5 pb-5'>
          <div className='col-12'>
            <FastMarquee>
             
               <div className='d-flex justify-content-between'>
               <div className='px-md-5 px-2'> <img src="./images/d1.png" width={"250px"} alt="/d1.png" /></div>
               <div className='px-md-5 px-2'> <img src="./images/d2.png" width={"250px"} alt="/d2.png" /></div>
             <div className='px-md-5 px-2'> <img src="./images/d3.png" width={"250px"} alt="/d3.png" /></div>
             <div className='px-md-5 px-2'> <img src="./images/d4.png" width={"250px"} alt="/d4.png" /></div>
               </div>
             
            
            </FastMarquee>
          </div>
        </div>
      </div>
    </div>
  );
}
