import React from "react";
import HomeSection1 from "./Home/HomeSection1";
import HomeSection2 from "./Home/HomeSection2";
import HomeSection3 from "./Home/HomeSection3";
import HomeSection4 from "./Home/HomeSection4";
import { Helmet } from "react-helmet";
import HomeSection5 from "./Home/HomeSection5";

export const HomePage = () => {
  return (
    <div>
      <Helmet>
      
      
        <title>
          Minaxi Palace Hotel in Bhinmal, Minaxi palace Hotel and Restaurant in
          Bhinmal ,Best Hotel in Bhinmal,Best Hotel Near Railway Station Bhinmal
        </title>
        <meta
          name="description"
          content="Minaxi Palace Hotel & Restaurant Rajasthan India, is our 3-star luxury hotel, located Near Rajput Circuit House, Opp. Jaswantpura Railway Crossing, Bhinmal Book & get the best hotel deals. 
"
        />
        <meta
          name="keyword"
          content="Minaxi Palace Hotel in Rajasthan|best Hotel in Rajasthan|best hotel in Bhinmal|Best  Restaurant in Bhinmal|Hotel in Bhinmal Rajasthan|Best minaxi palace Hotel near by|minaxi hotel near by jalore|Minaxi palace banquet in bhinmal|Best Restaurant in Bhinmal Rajasthan|Top  minaxi palace Hotels in Rajasthan|Best Hotel Near by bhinmal|best hotels in bhinmal|hotels in jalore rajasthan|bhinmal rajasthan jain temple|bhinmal temple|Top 5 hotels in bhinmal|Top 7 hotels in bhinmal|Top 10 hotels in bhinmal|bhinmal|"
        />

        <link rel="canonical" href="https://www.minaxipalace.in/" />
       


     


      </Helmet>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
      <HomeSection5 />
    </div>
  );
};
