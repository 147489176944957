import React, { useEffect, useState } from "react";
import CountUp from "react-countup";

const HomeSection4 = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const cardData = [
    { id: 1, hours: 24, title: "HoursOpen" },
    { id: 2, hours: 21, title: "Rooms" },
    { id: 3, hours: 200, title: "Happy Customer" },
    { id: 4, hours: 100, title: "Seat Restro" }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      
      setActiveIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    }, 1000); 

    return () => {
      clearInterval(interval); 
    };
  });
  return (
    <div style={{ position: "relative", width: "100%", height: "400px" }}>
      <div
        className="row m-0"
        style={{
          backgroundImage: `url("./images/counterbg.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          alt:"/counterbg.jpg",
          
        }}
      >
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="row justify-content-around">
            <div className="col-md-6 col-lg-3 d-block d-xl-none" style={{ width: "300px", height: "300px" }}>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{ width: "100%", height: "100%" }}>
                <div className="carousel-indicators">
                  {cardData.map((card, index) => (
                    <button
                      key={index}
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      
                      className={index === activeIndex ? "active" : ""}
                      aria-current={index === 0 ? "true" : "false"}
                      aria-label={`Slide ${index + 1}`}
                    ></button>
                  ))}
                </div>
                <div className="carousel-inner">
                  {cardData.map((card, index) => (
                    <div key={index} className={`carousel-item ${index === activeIndex ? "active" : ""}`} style={{ width: "100%", height: "100%" }}>
                      <div className="card bg-transparent border-0 border-white" style={{ width: "100%", height: "100%" }}>
                        <div className="text-center p-md-5 mx-md-3 bg-white" style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <div className="  align-items-center" style={{height:"200px"}}>
                            <div className="m-5" style={{ backgroundColor: card.bgColor }}></div>
                            <p className="text-white counter-circle1" style={{ fontSize: "2rem", fontWeight: "bold"}}>
                            <CountUp start={0} end={card.hours} duration={5} style={{ textTransform: "lowercase", zIndex: "1" }} />
                           
                      </p>
                      <p className="fs-5 mb-0 text-dark fw-bold pt-md-2">{card.title}</p>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {cardData.map((card) => (
              <div key={card.id} className="col-md-6 col-lg-3 d-none d-xl-block">
                <div className="card bg-transparent border-0 border-white">
                  <div className="text-center p-md-5 mx-md-3 bg-white" style={{ height: "230px" }}>
                    <div className="rounded-circle counter-circle align-items-center">
                      <div className="bg-counter" style={{ backgroundColor: card.bgColor }}></div>
                      <p className="text-white counter-number mb-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                        <CountUp start={0} end={card.hours} duration={5} style={{ textTransform: "lowercase", zIndex: "1" }} />
                      </p>
                    </div>
                    <p className="fs-5 mb-0 text-dark fw-bold pt-md-2">{card.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection4;
