import React from "react";
import AboutSection1 from "./About/AboutSection1";

import AboutSection3 from "./About/AboutSection3";
import { Helmet } from "react-helmet";

export default function AboutPage() {
  return (
    <div>
      <Helmet>
      
        <title>Top Hotels In Bhinmal Rajasthan, Best Hotels In Bhinmal</title>
        <meta
          name="description"
          content="Experience the essence of Minaxi Palace Hotel & Restaurant with luxury Facilities.About Minaxi Palace Hotel & Restaurants."
        />
        <meta
          name="keyword"
          content="Top Hotels In Bhinmal Rajasthan|About minaxi palace Hotels Near jalore|List of hotels in Rajasthan india|Affordable Hotels near by bhinmal|Rajasthan hotels and restaurant|best hotel near by bhinmal|Hotel and restaurant near by jalore|Minaxi Palace Hotel and Restaurant in Bhinmal|List of restaurant in rajasthan|3 star hotel and restaurant in rajasthan|3 star hotels in near by bhinmal|best hotels in Rajasthan|hotel in rajasthan|bhinmal rajasthan jain temple|hotel in jalore rajasthan|best hotel in bhinmal|best hotel near bhinmal|bhinmal temple|best hotel near raniwara"
        />
       
        <link rel="canonical" href="https://www.minaxipalace.in/about" />
  
     
      
      </Helmet>
      
      <AboutSection1 />

      <AboutSection3 />
    </div>
  );
}
