import React from "react";


function PrivacyPolicy() {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  return (
    <div>
      <div className="container">
        <div className="row m-2 m-md-5">
          <div className="col-12 ">
            <h3 className="text-center  p-3">
              Privacy Policy for MINAXIPALACE HOTEL & RESTAURANT
            </h3>
            <p className="pt-5" style={{ fontSize: "18px" }}>
              At{" "}
              <span className="fw-bold">MINAXIPALACE HOTEL & RESTAURANT</span>,
              we are committed to protecting your privacy. This Privacy Policy
              explains how we collect, use, disclose, and safeguard your
              information when you visit our website{" "}
              <span className="fw-bold">
                <a
                  href="https://www.minaxipalace.in/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  https://www.minaxipalace.in/
                </a>
              </span>{" "}
              including any other mobile website, or mobile application related
              or connected there to{" "}
              <span className="fw-bold">
                {" "}
                <a
                  href="https://www.minaxipalace.in/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  (https://www.minaxipalace.in/)
                </a>
              </span>
              Please read this privacy policy carefully. If you do not agree
              with the terms of this privacy policy, please do not access the
              site.
            </p>
            <h5 className="pt-4">Information We Collect</h5>
            <p className="pt-3" style={{ fontSize: "18px" }}>
              We collect personal information that you voluntarily provide to us
              when registering at the Site, expressing an interest in obtaining
              information about us or our products and services, when
              participating in activities on the Site, or otherwise contacting
              us.
            </p>
            <p className="pt-3" style={{ fontSize: "18px" }}>
              The personal information that we collect depends on the context of
              your interactions with us and the Site, the choices you make, and
              the products and features you use. The personal information we
              collect may include the following:
            </p>
          </div>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            ● Personal Information Provided by You. We collect names; phone
            numbers; email addresses; mailing addresses; usernames; passwords;
            contact preferences; billing addresses; debit/credit card numbers;
            and other similar information.
          </p>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            ● Payment Data. We may collect data necessary to process your
            payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code
            associated with your payment instrument. All payment data is stored
            by our payment processor and you should review its privacy policies
            and contact the payment processor directly to respond to your
            questions.
          </p>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            ● All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h5 className="pt-4">Disclosure of Information</h5>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            ● By Law or to Protect Rights. If we believe the release of
            information about you is necessary to respond to legal process, to
            investigate or remedy potential violations of our policies, or to
            protect the rights, property, and safety of others, we may share
            your information as permitted or required by any applicable law,
            rule, or regulation. This includes exchanging information with other
            entities for fraud protection and credit risk reduction.
          </p>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            ● Third-Party Service Providers. We may share your information with
            third parties that perform services for us or on our behalf,
            including payment processing, data analysis, email delivery, hosting
            services, customer service, and marketing assistance.
          </p>
          <h5 className="pt-4">Changes to This Policy</h5>
          <p className="pt-3" style={{ fontSize: "18px" }}>
            We may update this privacy policy from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy policy, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting
            your information.
          </p>
          <h5 className="pt-4">Contact Us</h5>
          <p>
            If you have questions or comments about this privacy policy, you may
            email us at{" "}
            <span
              className="fw-bold"
              onClick={() => handleEmailClick("minaxipalace@gmail.com")}
            >
              <a href="/" style={{ textDecoration: "none", color: "black" }}>
                minaxipalace@gmail.com
              </a>
            </span>
          </p>
          <p className="pt-1 pt-md-3">
            <span className="fw-bold">MINAXI PALACE HOTEL</span>
            <br /> Ashapuri Market Near Rajput Circuit House,
            <br /> Opp. Jaswantpura Railway Crossing, Bhinmal.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
