
import React from 'react';

export default function HomeSection1() {
  

  return (
    <div 
      className='container-fluid d-flex align-items-center justify-content-center background-container' 
      style={{ 
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/homepage1.jpg")', 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        opacity: 0.9, 
        minHeight: 'calc(100vh - 12vh)', 
        color: 'white' ,
        alt:"/homepage1.jpg"
      }}
    >
      <div className='text-center'>
     
        <p
          className="text-capitalize animate__animated animate__fadeInTopLeft masking background-text"
          style={{ fontSize: 65, letterSpacing: 1 }}
          
        >
          MINAXI PALACE HOTEL
        </p>
        <a href="https://india.stayezeepms.co.in/hotelbooking/190723590"> <button className="btn text-white p-2" type="button" style={{backgroundColor:"#EE5A24",width:"150px"}}>BOOK NOW</button></a>
      <br/>
      <img src="./select2.png" alt='/select2.png' style={{ width:"100px", height:"100px",  animation: 'moveUpDown 2s infinite' }} />
      </div>
    </div>
  );
}
