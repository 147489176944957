
import React from 'react';

const HomeSection3 = () => {

  const services = [
    {
      icon: "./images/5-stars.png",
      title: "Luxury Feeling",
      alt:"/5-stars.png",
      description: "Our team always be responsible for your luxuries feelings."
    },
    {
      icon: "./images/wifi.png",
      title: "Free Wifi",
      alt:"/wifi.png",
      description: "High Speed Wi-Fi available in Restaurant & Rooms."
    },
    {
      icon: "./images/24-7.png",
      title: "24*7 Reception",
      alt:"/24-7.png",
      description: "24*7 Reception available for continuous service for in-house guests."
    },
    {
      icon: "./images/electric-power.png",
      title: "Power Backup",
      alt:"/electric-power.png",
      description: "Power Backup facility available in complete building of Hotel."
    },
    {
      icon: "./images/food.png",
      title: "Food & Beverages",
      alt:"/food.png",
      description: "F&B available in restaurant & also in room service for  guest."
    },
    {
      icon: "./images/menu.png",
      title: "Buffet",
      alt:"/menu.png",
      description: "Buffet facility available for party, birthday, corporates etc."
    },
    {
      icon:"./images/time.png",
      title:"Cab Facility",
      alt:"/time.png",
      description:"Pick-up and drop-off in the pluses near the hotel."
    },
    {
      icon:"./images/jainfood.png",
      title:"Jain Food Available",
      alt:"/jainfood.png",
      description:"Provided jain food on order by any tourist.",
    },
    {
      icon:"./images/laundry.png",
      title:"Laundry service",
      alt:"/laundry.png",
      description:"A deals with washing,drying and ironing the clothes or any types of garments.",
    }
  ];

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="text-center pt-5">
              <p>MINAXI PALACE HOTEL, BHINMAL</p>
             
              <p style={{fontSize:"40px",fontWeight:"500"}} className="services-text"> <span style={{ color: "#EE5A24" }}>O</span>ur Services & Facility</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center m-md-5 m-2 pb-5">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-md-5 col-12 rounded mt-3 mx-4"
              style={{  backgroundColor: "#ecf0f1",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            >
              <div className="d-flex">
                <div className="p-3 m-1">
                  <img src={service.icon} alt={service.alt} style={{width:"60px",height:"60px"}}/>
                
               
                 
                </div>
                <div>
                  <p className="fs-md-3 fs-4 fw-bold pt-1">{service.title}</p>
                  <p className=''>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeSection3;
