import React from 'react'

export default function HomeSection2() {
  return (
    <div>
        <div className="container">
  <div className="row mt-md-5 mb-md-5 mt-0 mb-0 pt-4 pt-md-3">
    <div className=" col-md-6">
      <h1>
        <span style={{ color: "#EE5A24" }}>A</span>bout Us
      </h1>
    
      <p  style={{fontSize:"24px",fontWeight:"500"}}>Make Your Golden Memory With Us!</p>
      <div className="pt-3">
        <p className="fs-5">
          Minaxi Palace Hotel, Bhinmal is a perfect place to stay in Bhinmal.Located in the heart of Bhinmal, Minaxi Palace Hotel offers an exceptional blend of affordability and luxury. Our budget-friendly hotel in Bhinmal, Rajasthan, is ideally situated near prominent landmarks such as Rajput Circuit House, Ashapuri Market, and Jaswantpura Railway Crossing. This central location ensures that guests have easy access to the city’s major attractions and transportation hubs, making it an ideal choice for both business and leisure travellers.
        </p>
        <a href='https://maps.app.goo.gl/gnGhxsy1RHjtBEf1A' className='text-decoration-none text-dark'><p className="fs-5 fw-bold">
          Note : Note: Ashapuri Market is located in Bhinmal, Rajasthan, next to the Rajput Circuit House and the Jaswantpura Railway Crossing. 450 meters from the train station and 750 meters from the bus stop. The distance to Jodhpur Airport is 209 km.
        </p></a>
        <div className="fs-6">
          Check Out Time : 10 AM (Sharp)
          <br />
          Check Inn Time : 12 Noon
          <p />
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <img
        src="./images/homepage2.jpg"
        className="img-fluid"
        height="450px"
        width="590px"
        alt='/homepage2.jpg'
      />
    </div>
  </div>
</div>
    </div>
  )
}
