import React from 'react';

export default function AboutSection3() {
  return (
    <div className='bg-light'>
      <div className="container-fluid bg-white p-4">
        <div className="row mt-md-5 mb-md-5 mt-0 mb-0 pt-4 pt-md-3">
          <div className="col-md-5">
            <h1>
              <span style={{ color: "#EE5A24" }}>M</span>inaxi Palace Hotel, Bhinmal!
            </h1>

            <div className="pt-3">
              <p className="fs-5">
              Minaxi Palace Hotel and Restaurant is your premier destination for a luxurious yet budget-friendly stay in Bhinmal. Conveniently located at Ashapuri Market, Near Rajput Circuit House, Opposite Jaswantpura Railway Crossing, Bhinmal, our hotel offers the perfect blend of comfort and sophistication, comparable to any luxury hotel in the region. Our well-trained staff is dedicated to providing top-notch service, ensuring a stress-free and memorable stay.
Our hotel features meticulously designed rooms equipped with modern amenities for a relaxing experience. Enjoy the best of both worlds with our comprehensive in-room services and exceptional Indian cuisine available at our restaurant. Whether you prefer dining in our elegantly appointed restaurant or opting for convenient in-room dining, our menu offers a diverse selection of delectable dishes crafted from the finest ingredients.

              </p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6 mb-3 d-none d-md-block hover-container">
               <div className='image-wrapper1'>
               <img
                  src="./images/about1.jpg"
                  className="img-fluid hover-scale"
                  alt='/about1.jpg'
                  style={{ height: "415px" }}
                />
               </div>
              </div>
              <div className="col-md-6  col-12 mb-3">
              <div className="row">
              <div className="col-12 mb-3  d-block d-md-none hover-container">
                   <div className='image-wrapper'>
                   <img
                      src="./images/about1.jpg"
                      className="img-fluid hover-scale"
                      alt='/about1.jpg'

                      style={{ height: "200px", width: "100%", objectFit: "cover",
                     }}
                    />
                   </div>
                  </div>
                  <div className="col-12 mb-3 hover-container">
                   <div className='image-wrapper'>
                   <img
                      src="/images/about2.jpg"
                      className="img-fluid hover-scale"
                      alt='/about2.jpg'

                      style={{ height: "200px", width: "100%", objectFit: "cover",
                     }}
                    />
                   </div>
                  </div>
                  <div className="col-12 hover-container">
                  <div className='image-wrapper'>
                  <img
                      src="./images/about3.jpg"
                      className="img-fluid hover-scale"
                      alt='/about2.jpg'
                      style={{ height: "200px", width: "100%", objectFit: "cover" }}
                    />
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
                    <div className='col-12'>
                      <p className='fs-5'>At Minaxi Palace, your pleasure and comfort come first. Our restaurant offers a cozy, welcoming ambiance perfect for big events as well as everyday meals. With our commitment to exceptional service and high-quality dining, Minaxi Palace Hotel and Restaurant ensures that your visit to Bhinmal is both enjoyable and luxurious.</p>
                     <h5 className=''><strong>Luxurious Accommodation with Modern Amenities</strong></h5>
                     <p className='fs-5'>At Minaxi Palace Hotel, we prioritize your comfort and convenience. Our well-appointed rooms are designed to provide a luxurious experience while being budget-friendly. Air conditioning, comfy bedding, and fast internet are amenities found in every room. Guests can enjoy modern amenities such as flat-screen TVs, 24-hour room service, and complimentary toiletries. Whether you're visiting Bhinmal for business or relaxation, our hotel offers the perfect blend of comfort and elegance</p>
                     <h5 className='mt-5'><strong>Exceptional Services and Local Exploration</strong></h5>
                     <p className='fs-5'>Minaxi Palace Hotel is renowned for its exceptional service and attentive staff. We offer a range of amenities including complimentary Wi-Fi, 24-hour room service, and laundry facilities. Our efficient check-in and check-out process, with check-in at 12 PM and check-out by 10 AM, adds to the convenience. The hotel’s location makes it an excellent base for exploring Bhinmal's vibrant markets and cultural heritage. As one of the top-rated hotels in Bhinmal, we are dedicated to providing modern amenities and outstanding service to ensure a memorable stay.</p>
                    </div>
        </div>
      </div>
    </div>
  );
}
