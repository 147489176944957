import React from 'react'

const roomData = [
  {
    id: 1,
    title: "Deluxe Room",
    image: "./images/deluxroom.jpg",
    features: [
      "Manage All rooms",
      "Clear and well-grouped options",
      "Rooms Availability Management"
    ]
  },
  {
    id: 1,
    title: " Super Deluxe Room",
    image: "./images/superdeluxroom.jpg",
    features: [
      "Manage All rooms",
      "Clear and well-grouped options",
      "Rooms Availability Management"
    ]
  },
 
];
export default function RoomsSection2() {
  return (
    <div>
      <div className='container'>
     <div className='row'>
     <div className="col-12  m-2">
            <div className="text-center pt-5 ">
              <p>
             Accommodation</p>
              
              <p style={{fontSize:"40px",fontWeight:"500"}} className='text-center room-text'> <span style={{ color: "#EE5A24" }}>D</span>iscover Our Room</p>
            </div>
          </div>
      </div>      
      <div className="row justify-content-center justify-content-md-around"> 
  {roomData.map(room => (
    <div key={room.id} className="card col-sm-6 col-md-4 m-2 mt-md-4 mb-md-4" style={{ maxWidth: "34rem",border:"none"}}>
      <img src={room.image} className="img-fluid card-img-top position-relative" alt={room.image} />
      <div className='position-absolute pt-3'><p className='bg-danger text-white fs-6 px-1'>{room.title}</p></div>
      <div className="card-body bg-success text-white ">
        <p className="card-text"><span className='fs-4'>Room Management</span>
          <ul className='list-unstyled'>
            {room.features.map((feature, index) => (
              <li key={index} className='px-2 py-1'><i className="fa-solid fa-plus px-2"></i>{feature}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  ))}
</div>

</div>
<div className="container">
        <div className="row">
          <div className="col-12 mt-5">
           
            <p>
              <p>
               
The standout feature of the room is the luxurious bathroom, a private oasis designed for tranquillity. Clad in gleaming tiles and equipped with premium fixtures, the spacious shower features a rainfall showerhead that envelops you in a soothing cascade of water. Complimentary toiletries and plush towels enhance the pampering experience, while the overall design exudes a spa-like serenity. Whether you’re indulging in a long, hot soak or a quick refreshing rinse, the bathroom guarantees a rejuvenating and relaxing experience.

              </p>
              <p>Perfect for couples seeking a romantic retreat or solo travellers desiring a luxurious home away from home, this air-conditioned room provides unparalleled comfort and convenience. With its thoughtfully curated amenities and elegant design, it sets the stage for an unforgettable stay, allowing you to fully immerse yourself in luxury throughout your visit. Experience the epitome of relaxation and sophistication in our luxury accommodations.</p>
            </p>
          </div>
        </div>
      </div>
                </div>
           
  )
}
