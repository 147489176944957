import React from "react";
import RoomsSection1 from "./Rooms/RoomsSection1";
import RoomsSection2 from "./Rooms/RoomsSection2";
import { Helmet } from "react-helmet";
export default function RoomsPage() {
  return (
    <div>
      <Helmet>
      
        <title>Best Hotels rooms in Rajasthan,Top Hotels near by bhinmal</title>
        <meta
          name="description"
          content="Experience 4-star hotel in BHINMAL when you stay in Minaxi Palace Hotel & Restaurant.Our hotel features luxurious rooms near Jalore.
"
        />
        <meta
          name="keyword"
          content="luxurious hotels in Rajasthan|luxurious restaurant in Bhinmal|3 star hotels rooms in rajasthan|Affordable Hotels near by bhinmal|best hotels near by bhinmal|list of luxurious hotels in rajasthan|minaxi palace hotel|best room in rajasthan|Rooms Minaxi Palace Hotel in Bhinmal|best hotels near by jalore|hotel in jalore rajasthan|bhinmal rajasthan jain temple|bhinmal temple"
        />
        
        <link rel="canonical" href="https://www.minaxipalace.in/rooms" />
     

      

      </Helmet>
      <RoomsSection1 />
      <RoomsSection2 />
    </div>
  );
}
