import React from 'react'


export default function AboutSection1() {
  return (
    <div>
        
        <div className="card bg-dark text-white" style={{borderRadius:"0px",border:"none"}}>
  <img src="./images/aboutbanner.jpg" className="card-img img-fluid" alt="/aboutbanner.jpg"/>
  <div className="card-img-overlay d-flex justify-content-center align-items-center">
            <p className="card-title text-center animate__animated animate__fadeInDown masking text-uppercase">welcome to minaxi palace Hotel</p>
          </div>
</div>
<div>
  
  </div>
    </div>
  )
}
