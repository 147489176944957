import React from 'react';


const Navbar = () => {
  
  
  return (
    <div> 
      <nav className="navbar navbar-expand-lg navbar-light">

        <div className="container">
        
          <a href='/'><img src="./images/lastlogo.png" alt='/lastlogo.png' height="60px"/>
            <img src='./images/hotelname.png' alt='/hotelname.png' width="200px" /></a>
         
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/about"}>About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/rooms"}>Rooms</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/restaurant"}>Restaurant</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/banquet"}>Banquet</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/gallery"}>Gallery</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/attraction"}>Attraction</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={"/contact"}>Contact</a>
              </li>
            </ul>
            <form className="d-flex">
             <a href="https://india.stayezeepms.co.in/hotelbooking/190723590"> <button className="btn text-white" type="button" style={{backgroundColor:"#218c74"}}>BOOK NOW</button></a>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
