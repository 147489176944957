import React from "react";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt} from "react-icons/fa";
import { IoLocationSharp, IoLogoWhatsapp } from "react-icons/io5";

export default function Footer() {
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  const handleYouTubeClick = () => {
    window.open("https://www.youtube.com/@MINAXIPALACE");
  };
  const handlelocation = () => {
    window.open("https://maps.app.goo.gl/nZuNcLej3Nr5SSU59");
  };

  const handleInstagramClick = () => {
    window.open(
      "https://www.instagram.com/minaxipalace?igsh=MXg1Z2lla3g1NWZmZQ=="
    );
  };
  const facebookCllick = () => {
    window.open("https://www.facebook.com/profile.php?id=61556984322666");
  };
  const handlewhasApp = () => {
    window.open("https://wa.link/9uy80b");
  };

  return (
    <div>
      <footer
        className="text-center text-lg-start  text-muted"
        style={{ backgroundColor: "#dfe6e9" }}
      >
        <section className="d-flex justify-content-center justify-content-lg-between"></section>

        <section className="footer ">
          <div className="container text-center text-md-start  text-dark mt-5">
            <div className="row mt-3 ">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    src="./images/lastlogo.png"
                    alt="/lastlogo.png"
                    style={{ height: "60px" }}
                  />
                  <img
                    src="./images/hotelname.png"
                    alt="/hotelname.png"
                    style={{ height: "60px" }}
                  />
                </h6>
                <p>
                  Minaxi Palace Hotel, Bhinmal is a perfect place to stay in
                  Bhinmal. It has a tradition of hospitality and personalized
                  attention that makes it a distinguished address in Bhinmal.
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="/about" title="about"
                  className="text-reset text-decoration-none">
                    About
                  </a>
                </p>
                <p>
                  <a href="/rooms" title="rooms" className="text-reset text-decoration-none">
                    Rooms
                  </a>
                </p>
                <p>
                  <a
                    href="/banquet"
                    title="banquet"
                    className="text-reset text-decoration-none"
                  >
                    Banquet
                  </a>
                </p>
                <p>
                  <a
                    href="/gallery"
                    title="gallery"
                    className="text-reset text-decoration-none"
                  >
                    Gallery
                  </a>
                </p>
                <p>
                  <a
                    href="/attraction"
                    title="attraction"
                    className="text-reset text-decoration-none"
                  >
                    Attraction
                  </a>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a
                    href="/privacy-policy"
                    title="privacy-policy"
                    className="text-reset text-decoration-none"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p>
                  <a
                    href="/terms-condition"
                    title="terms-condition"
                    className="text-reset text-decoration-none"
                  >
                    Terms and Conditions
                  </a>
                </p>
                <p>
                  <a
                    href="/cancellation"
                    title="cancellation"
                    className="text-reset text-decoration-none"
                  >
                    Cancellation and Refund
                  </a>
                </p>

                <p>
                  <a
                    href="https://india.stayezeepms.co.in/hotelbooking/190723590"
                    title="booking"
                    className="text-reset text-decoration-none"
                  >
                    Book Now
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Get in Touch</h6>
                <p className="text-start px-2" onClick={handlelocation}>
                  <a
                    href="/https://maps.app.goo.gl/nZuNcLej3Nr5SSU59"
                    target="_blank"
                    title="address"
                  >
                    <IoLocationSharp style={{ color: "black" }} />
                  </a>
                  Ashapuri Market Near Rajput Circuit House, Opp. Jaswantpura
                  Railway Crossing, Bhinmal
                </p>
                <p
                  className="text-start px-2"
                  onClick={() => handleEmailClick("minaxipalace@gmail.com")}
                >
                  <a href="minaxipalace@gmail.com" 
                  title="mail"
                  target="_blank">
                    <MdEmail style={{ color: "black" }} />
                  </a>
                  minaxipalace@gmail.com
                </p>
                <p
                  className="text-start px-2"
                  onClick={() => handlePhoneClick("+919460785066")}
                >
                  <a href="/" target="__blank" title="phone">
                    <FaPhoneAlt style={{ color: "black" }} target="_blank" />
                  </a>{" "}
                  +91-9982299252{" "}
                </p>

                <p className="text-start px-2" onClick={handlewhasApp}>
                  <a href="/https://wa.link/9uy80b" target="_blank" title="whatsApp">
                    <IoLogoWhatsapp style={{ color: "#009432" }} />
                  </a>
                  +91-9783299251
                </p>
                <div className="d-flex">
                  <div
                    className="mx-1 footer_icon"
                    style={{ backgroundColor: "#1877F2" }}
                    onClick={facebookCllick}
                  >
                    <a
                      href="/https://www.facebook.com/profile.php?id=61556984322666"
                      title="facebook"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f px-1 fs-6 text-white"></i>
                    </a>
                  </div>

                  <div
                    className="mx-1 footer_icon"
                    style={{
                      background:
                        "linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)",
                    }}
                    onClick={handleInstagramClick}
                  >
                    <a
                      href="/https://www.instagram.com/minaxipalace?igsh=MXg1Z2lla3g1NWZmZQ=="
                      title="instagram"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram px-1 fs-6 text-white"></i>
                    </a>
                  </div>

                  <div
                    className="mx-1 footer_icon"
                    style={{ backgroundColor: "#FF0000" }}
                    onClick={handleYouTubeClick}
                  >
                    <a href="/https://www.youtube.com/@MINAXIPALACE" title="youtube">
                      <i className="fa-brands fa-youtube px-1 fs-6 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 border-top border-1 d-flex justify-content-center">
                <div></div>
                <div>
                  <p className="text-center p-3">
                    {" "}
                    @2024{" "}
                    <span className="fw-bold text-uppercase">
                      Minaxi Hotel & Restaurant.
                    </span>{" "}
                    All rights reserved.
                  </p>
                </div>
                {/* <div className="mt-2 ">
                  <a
                    href="/"
                    title="giveup"
                    className="top p-2"
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    <i className="fa-solid fa-arrow-up px-2 py-2"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
}
