import React from "react";
import { Helmet } from "react-helmet";
export const Restarant = () => {
 
  const images = [
    "./images/resto1.jpg",
    "./images/resto2.jpg",
    "./images/resto3.jpg",
    "./images/resto4.jpg",
    "./images/resto5.jpg",
    "./images/resto6.jpg",
    "./images/resto7.jpg",
    "./images/resto8.jpg",
    "./images/resto9.jpg",
  ];

  return (
    <div>
      <Helmet>
     
        <title>
          Best hotel, restaurant and banquet in Rajasthan,Top hotels and
          restaurant in bhinmal
        </title>
        <meta
          name="description"
          content="Enjoy a variety of 5-star Food at Minaxi Palace Hotel & Restaurant.Our Restaurant serves Punjabi , South-Indian and Chinese food and more.Reserve now."/>
        <meta
          name="keyword"
          content="List of hotels near by jalore|Hotels and resturant in rajasthan|hotel and banquet near rajasthan|3 star restarant in rajasthan|Minaxi Palace Hotel and Restaurant in rajasthan|Minaxi palace restaurant in Bhinmal|bhinmal|hotel in jalore rajasthan|bhinmal rajasthan jain temple|bhinmal temple"/>
       
        <link rel="canonical" href="https://minaxipalace.in/restaurant" />
     
    

      </Helmet>
      <div className="text-white position-relative">
        <img
          src="./images/contact.jpg"
          className="card-img img-fluid d-flex"
          alt="/contact.jpg"
          style={{
            width: "100%",
            height: "420px",
            objectFit: "cover",
            border: "none",
            borderRadius: "0px",
          }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <p className="card-title text-uppercase fs-3">Restaurant</p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <h4>Welcome to Minaxi Palace Hotel and Restaurant</h4>
            <p className="mt-3">
            Experience a memorable stay at Minaxi Palace Hotel Bhinmal, conveniently located near the railway station and bus stand. Our luxurious budget hotel offers well-furnished rooms with modern amenities, blending comfort with affordability. Situated at Ashapuri Market, Near Rajput Circuit House, Opposite Jaswantpura Railway Crossing, Bhinmal, Rajasthan, we are just 450 meters from the railway station and 750 meters from the bus stand, with Jodhpur Airport approximately 209 km away. With a check-in time of 12 noon and check-out at 10 AM sharp, we ensure a seamless and pleasant stay.
            </p>
            <p>
              <p>
               
              At Minaxi Palace Hotel, we prioritize your comfort with a range of services and facilities. Enjoy free high-speed Wi-Fi in both our restaurant and rooms, 24/7 reception for continuous assistance, and reliable power backup. Our restaurant offers a variety of delectable food and beverages, including authentic Jain food upon request. Host events or parties with our buffet facilities, and take advantage of our convenient cab facility for easy travel. Our professional laundry service and attentive staff further enhance your stay, ensuring all your needs are met with care and efficiency.

              </p>
             </p>
            <p>
  Our restaurant and five well-appointed rooms provide a comfortable retreat with a seating capacity of 10, ideal for both dining and relaxation. Discover more about our offerings, including detailed information about our rooms, banquets, and local attractions on our website. For reservations or inquiries, contact us at Ashapuri Market, Near Rajput Circuit House, Opposite Jaswantpura Railway Crossing, Bhinmal, or email us at
  <a className="text-decoration-none fw-bold px-2 text-dark" href="mailto:minaxipalace@gmail.com" title="mail" target="_blank"  rel="noreferrer" >minaxipalace@gmail.com</a>
  and call
  <a className="text-decoration-none fw-bold px-2 text-dark" href="tel:+919982299252" title="phone">+91-9982299252</a>
  or
  <a className="text-decoration-none fw-bold px-2 text-dark" href="tel:+919783299251" title="phone">+91-9783299251</a>.
  Book now and enjoy a stay that combines luxury with convenience.
</p>
<a href="https://play.google.com/store/apps/details?id=com.adsinc.mphotel" className="text-decoration-dark"><p className="text-dark">Booking Food online</p></a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 d-flex flex-wrap justify-content-center">
            {images.map((image, index) => (
              <div
                key={index}
                className="card mx-3 mb-4"
                style={{ width: "24rem", border: "none" }}
              >
                <img
                  src={image}
                  className="card-img-top"
                  alt={`Restaurant ${index + 1}`}
                  style={{ borderRadius: "0px" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
