import React, { useState, useEffect } from 'react';

export default function RoomsSection1() {
  const roomImages = [
    "./images/room1.jfif",
    "./images/room2.jfif",
    "./images/room3.jfif",
    "./images/room4.jfif",
    "./images/room5.jfif",
    "./images/room6.jfif"
  ];

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % roomImages.length);
    }, 1000); 

    return () => clearInterval(intervalId); 
  }, [roomImages.length]);

  const currentSlide = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-12">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {roomImages.map((image, index) => (
                <div key={index} className={`carousel-item ${index === slideIndex ? 'active' : ''}`}>
                  <img src={image} className="d-block w-100" alt={`Room ${index + 1}`} style={{ maxHeight: "500px", objectFit: "cover" }} />
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
       
        <div className='col-md-6 d-none d-md-block col-12 bg-light p-5'>
          
          <h1><span style={{color:"#EE5A24"}}>R</span>ooms</h1>
          <div>
            <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
            <i className="fa-solid fa-star " style={{color:"#F79F1F"}}></i>
            <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
            <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
            <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
          </div>
          <p className='pt-3'>Our luxury air-conditioned rooms offer the perfect choice for those seeking both comfort and indulgence, whether for a short weekend getaway or an extended vacation. Upon entering, you are immediately enveloped in an ambiance of refined sophistication and modern convenience. The meticulously appointed interiors boast high-end furnishings and decor, creating an atmosphere of timeless elegance. Enjoy the plush carpeting or gleaming hardwood floors underfoot, and appreciate the well-designed layout that maximizes both form and function, providing ample space to relax and unwind. Thoughtful touches, such as adjustable lighting, ample storage, and convenient power outlets, ensure that all your needs are met.</p>
        </div>
      </div>
      
      {/* Slider with six columns */}
      <div className="row mt-md-5 mt-3">
        {roomImages.map((image, index) => (
          <div key={index} className="col-md-2 col-4 pb-2">
            <img
              className="img-fluid"
              src={image}
              style={{ cursor: "pointer" }}
              alt={`Room ${index + 1}`}
              onClick={() => currentSlide(index)}
            />
          </div>
        ))}
      </div>
      <div className="container">
        <div>
          <div className='row'>
            <div className='col-md-6 d-block d-md-none col-12 bg-light p-md-5 p-4'>
              <h1><span style={{ color: "#EE5A24" }}>R</span>ooms</h1>
              
              <div>
                <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
                <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
                <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
                <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
                <i className="fa-solid fa-star" style={{color:"#F79F1F"}}></i>
              </div>
              <p className="p-3">
              Our luxury air-conditioned rooms offer the perfect choice for those seeking both comfort and indulgence, whether for a short weekend getaway or an extended vacation. Upon entering, you are immediately enveloped in an ambiance of refined sophistication and modern convenience. The meticulously appointed interiors boast high-end furnishings and decor, creating an atmosphere of timeless elegance. Enjoy the plush carpeting or gleaming hardwood floors underfoot, and appreciate the well-designed layout that maximizes both form and function, providing ample space to relax and unwind. Thoughtful touches, such as adjustable lighting, ample storage, and convenient power outlets, ensure that all your needs are met.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
